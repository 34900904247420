@use './variables';

.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin-bottom: 2rem;
	transition: inherit;
	background-image: url('../../public/images/home_background.webp');
	background-size: auto;
	background-size: cover;
	position: relative;

	h1 {
		margin: 1rem;
		font-size: 3rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		color: variables.$black;
		transition: inherit;
		background-color: var(--gold);
		opacity: 0.85;
		padding: 1rem;
		border-radius: 0.25rem;
		-webkit-clip-path: polygon(
			20px 0%,
			100% 0px,
			100% 20px,
			100% calc(100% - 20px),
			calc(100% - 20px) 100%,
			0px 100%,
			0% calc(100% - 20px),
			0% 20px
		);
		clip-path: polygon(
			20px 0%,
			100% 0px,
			100% 20px,
			100% calc(100% - 20px),
			calc(100% - 20px) 100%,
			0px 100%,
			0% calc(100% - 20px),
			0% 20px
		);
	}

	h2 {
		margin: 1rem;
		opacity: 0.85;
		padding: 0.5rem 1rem;
		color: variables.$black;
		background-color: var(--gold);
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		transition: inherit;
		-webkit-clip-path: polygon(
			12px 0%,
			100% 0px,
			100% 12px,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0px 100%,
			0% calc(100% - 12px),
			0% 12px
		);
		clip-path: polygon(
			12px 0%,
			100% 0px,
			100% 12px,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0px 100%,
			0% calc(100% - 12px),
			0% 12px
		);
	}

	span {
		white-space: nowrap;
		margin: 0.5rem;
	}
}

.socials {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	flex-direction: row;
	transition: 0.25s ease;
	opacity: 0.85;

	a {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 8px;
		color: variables.$black;
		background-color: var(--gold);
		border: 2px solid transparent;
		transition: 0.25s ease;

		&:hover {
			-webkit-filter: brightness(110%);
			filter: brightness(110%);
			border-color: var(--secondary);
		}

		&:active {
			-webkit-filter: brightness(120%);
			filter: brightness(120%);
		}
	}

	h4 {
		display: none;
	}

	svg {
		width: 2.5rem;
		height: 2.5rem;
		margin: 0.5rem;
		color: variables.$black;
	}

	path {
		transition: 0.25s ease;
	}

	.linkedin {
		path:last-child {
			fill: var(--gold);
		}
	}
}

@media only screen and (min-width: 600px) {
	.home {
		h1 {
			font-size: 4rem;
		}
	}

	.socials {
		flex-direction: column;

		a {
			transform: translateX(calc(100% - 3.75rem));

			&:first-child {
				border-top-left-radius: 0.25rem;
			}

			&:last-child {
				border-bottom-left-radius: 0.25rem;
			}

			&:hover {
				transform: translateX(0);
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
				-webkit-filter: brightness(110%);
				filter: brightness(110%);
			}

			&:active {
				-webkit-filter: brightness(120%);
				filter: brightness(120%);
			}
		}

		h4 {
			display: block;
		}
	}
}

@media only screen and (min-width: 800px) {
	.socials {
		top: 70vh;
	}
}
