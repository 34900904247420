@use './variables';

.contact {
	padding: 2rem;
	margin-bottom: 5rem;

	.section_contact {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: 2rem;

		> h3 {
			min-width: 100px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}

	.container:not(:first-child) {
		display: flex;
		flex-direction: row;
		margin: 0.5rem;
	}

	.contact_method {
		display: block;
		margin: 1rem;
		color: var(--secondary);
		transition: 0.125s ease;

		h3 {
			padding: 0.5rem;
			margin: 0 auto;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			line-height: 2rem;
			transition: 0.75s ease;
		}

		&:hover {
			transition: 0.25s ease;
			transform: scale(1.25);
		}
	}

	.email,
	.github,
	.linkedin {
		a {
			display: block;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			margin: auto;
		}

		svg {
			display: block;
			margin: auto;
			width: 3rem;
			height: 3rem;
			color: var(--secondary);
			transition: 0.75s ease;
		}
	}

	.linkedin {
		path:last-child {
			transition: 0.75s ease;
			fill: var(--primary);
		}
	}
}
