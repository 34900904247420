@use './variables';

.header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 3rem;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	h2 {
		font-weight: 600;
		margin: 0 2rem;
	}

	hr {
		flex: 1;
		padding: 2px;
		border: none;
		background-color: var(--gold);
		max-width: 25%;
		margin: auto 0;
	}
}
