@use './variables';

.skills {
	padding: 2rem;

	.section_skills {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		margin: 1rem;
	}

	.container {
		flex: 1;
		margin: 1rem;
		text-align: center;
	}

	h3 {
		margin: 0 auto;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		padding-bottom: 0.25rem;
		border-bottom: 2px solid var(--gold);
	}

	.card {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: 1rem 0;
		padding: 0.5rem 0;
		max-width: 300px;
		background-color: variables.$blue;
		border-radius: 0.5rem;
	}

	.skill_item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0.5rem;
		width: 6rem;

		svg,
		img {
			width: 3rem;
			height: 3rem;
			margin: 0rem;
		}

		h4 {
			margin: 0.5rem;
		}
	}

	@media only screen and (min-width: 600px) {
		.card {
			max-width: 400px;
		}
	}

	@media only screen and (min-width: 768px) {
		.section_skills {
			flex-direction: row;
			align-items: flex-start;
		}

		.container {
			max-width: 500px;
			flex-direction: column;
		}

		.card {
			max-width: 500px;
		}
	}
}
