@use './variables';

.projects {
	padding: 2rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.section_projects {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 1rem;
		width: 100%;
		overflow: hidden;
	}

	.project_item {
		display: flex;
		flex-direction: column;
		min-width: 200px;
		padding: 1rem;
		background-color: variables.$blue;
		border-radius: 0.5rem;
		text-align: center;
		cursor: pointer;

		h3 {
			font-weight: 600;
			margin-top: 1rem;
			white-space: nowrap;
		}

		img {
			width: 100%;
			height: 100%;
		}

		&.earlierProject {
			transform: translateX(-4rem);
			opacity: 0;

			&[data-slideforward='1'] {
				-webkit-animation: earlierToPrevious 0.75s;
				animation: earlierToPrevious 0.75s;
			}
		}

		@-webkit-keyframes earlierToPrevious {
			0% {
				transform: translateX(-4rem);
			}

			100% {
				transform: translateX(calc(100% - 2rem));
				opacity: 0.5;
			}
		}

		@keyframes earlierToPrevious {
			0% {
				transform: translateX(-4rem);
			}

			100% {
				transform: translateX(calc(100% - 2rem));
				opacity: 0.5;
			}
		}

		&.previousProject {
			transform: translateX(-2rem);
			opacity: 0.5;

			&[data-slidebackward='1'] {
				-webkit-animation: previousToEarlier 0.75s;
				animation: previousToEarlier 0.75s;
			}

			&[data-slideforward='1'] {
				-webkit-animation: previousToCurrent 0.75s;
				animation: previousToCurrent 0.75s;
			}
		}

		@-webkit-keyframes previousToEarlier {
			0% {
				transform: translateX(-2rem);
			}

			100% {
				transform: translateX(calc(-100% - 4rem));
				opacity: 0;
			}
		}

		@keyframes previousToEarlier {
			0% {
				transform: translateX(-2rem);
			}

			100% {
				transform: translateX(calc(-100% - 4rem));
				opacity: 0;
			}
		}

		@-webkit-keyframes previousToCurrent {
			0% {
				transform: translateX(-2rem);
			}

			100% {
				transform: translateX(calc(100%));
				opacity: 1;
			}
		}

		@keyframes previousToCurrent {
			0% {
				transform: translateX(-2rem);
			}

			100% {
				transform: translateX(calc(100%));
				opacity: 1;
			}
		}

		&.currentProject {
			opacity: 1;

			&[data-slidebackward='1'] {
				-webkit-animation: currentToPrevious 0.75s;
				animation: currentToPrevious 0.75s;
			}

			&[data-slideforward='1'] {
				-webkit-animation: currentToNext 0.75s;
				animation: currentToNext 0.75s;
			}
		}

		@-webkit-keyframes currentToPrevious {
			0% {
				transform: translateX(0);
			}

			100% {
				transform: translateX(calc(-100% - 2rem));
				opacity: 0.5;
			}
		}

		@keyframes currentToPrevious {
			0% {
				transform: translateX(0);
			}

			100% {
				transform: translateX(calc(-100% - 2rem));
				opacity: 0.5;
			}
		}

		@-webkit-keyframes currentToNext {
			0% {
				transform: translateX(0);
			}

			100% {
				transform: translateX(calc(100% + 2rem));
				opacity: 0.5;
			}
		}

		@keyframes currentToNext {
			0% {
				transform: translateX(0);
			}

			100% {
				transform: translateX(calc(100% + 2rem));
				opacity: 0.5;
			}
		}

		&.nextProject {
			transform: translateX(2rem);
			opacity: 0.5;

			&[data-slidebackward='1'] {
				-webkit-animation: nextToCurrent 0.75s;
				animation: nextToCurrent 0.75s;
			}

			&[data-slideforward='1'] {
				-webkit-animation: nextToLater 0.75s;
				animation: nextToLater 0.75s;
			}
		}

		@-webkit-keyframes nextToCurrent {
			0% {
				transform: translateX(2rem);
			}

			100% {
				transform: translateX(calc(-100%));
				opacity: 1;
			}
		}

		@keyframes nextToCurrent {
			0% {
				transform: translateX(2rem);
			}

			100% {
				transform: translateX(calc(-100%));
				opacity: 1;
			}
		}

		@-webkit-keyframes nextToLater {
			0% {
				transform: translateX(2rem);
			}

			100% {
				transform: translateX(calc(100% + 4rem));
				opacity: 0;
			}
		}

		@keyframes nextToLater {
			0% {
				transform: translateX(2rem);
			}

			100% {
				transform: translateX(calc(100% + 4rem));
				opacity: 0;
			}
		}

		&.laterProject {
			transform: translateX(4rem);
			opacity: 0;

			&[data-slidebackward='1'] {
				-webkit-animation: laterToNext 0.75s;
				animation: laterToNext 0.75s;
			}
		}

		@-webkit-keyframes laterToNext {
			0% {
				transform: translateX(4rem);
			}

			100% {
				transform: translateX(calc(-100% + 2rem));
				opacity: 0.5;
			}
		}

		@keyframes laterToNext {
			0% {
				transform: translateX(4rem);
			}

			100% {
				transform: translateX(calc(-100% + 2rem));
				opacity: 0.5;
			}
		}

		@media only screen and (min-width: 600px) {
			min-width: 37%;
		}

		@media only screen and (min-width: 1000px) {
			min-width: 30%;
		}
	}

	.projects_controls {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin: 1rem;
	}

	.links {
		flex: 1;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		order: 10;
	}

	@media only screen and (min-width: 349px) {
		.links {
			order: initial;
		}
	}

	@media only screen and (min-width: 495px) {
		.links {
			flex: initial;
		}
	}

	.button {
		flex: 1;
		padding: 0.75rem 1rem;
		margin: 1rem;
		font-size: 1.25rem;
		text-align: center;
		color: variables.$black;
		background-color: var(--gold);
		border-radius: 5rem;
		border: 2px solid transparent;
		white-space: nowrap;
		transition: 0.25s ease;

		&:hover {
			border-color: var(--secondary);
			-webkit-filter: brightness(110%);
			filter: brightness(110%);
		}

		&:active {
			-webkit-filter: brightness(120%);
			filter: brightness(120%);
		}
	}

	.arrow {
		margin: 0 1rem;
		font-size: 1.5rem;
		padding: 0.75rem;
		border-radius: 4px;
		color: var(--secondary);
		transition: 0.25s ease;
		border: 2px solid transparent;
		cursor: pointer;

		&:hover {
			border-color: var(--secondary);
			background-color: var(--gold);
		}

		&:active {
			-webkit-filter: brightness(110%);
			filter: brightness(110%);
		}
	}
}
