@use './variables';

.about {
	padding: 2rem;

	img {
		display: none;
	}

	p {
		margin: 2rem;
		text-align: justify;

		svg {
			width: 3rem;
			height: 3rem;
		}

		&:nth-of-type(1) {
			svg {
				margin: 0.5rem;
				margin-right: 0;
				float: right;
			}
		}

		&:nth-of-type(2) {
			svg:first-child {
				margin: 0.5rem;
				margin-left: 0;
				float: left;
			}

			svg:last-child {
				margin: 0.5rem;
				margin-right: 0;
				float: right;
			}
		}
	}

	@media only screen and (min-width: 600px) {
		img {
			display: block;
			margin: 2rem;
			margin-left: 8rem;
			width: 160px;
			float: left;
		}

		article {
			max-width: 75%;
			margin: 2rem auto;
		}
	}

	@media only screen and (min-width: 768px) {
		img {
			width: 240px;
			height: -webkit-fit-content;
			height: -moz-fit-content;
			height: fit-content;
			float: none;
			margin: 2rem auto;
		}
	}

	@media only screen and (min-width: 1000px) {
		.container {
			display: flex;
			flex-direction: row;
		}

		img {
			width: 320px;
			height: -webkit-fit-content;
			height: -moz-fit-content;
			height: fit-content;
			float: none;
		}

		article {
			margin: 0;
		}
	}
}
