@use './variables';

.nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.container {
		position: relative;
		width: 90px;
		transition: width 0s ease;
	}

	ul {
		width: 100%;
		display: flex;
		flex-direction: column;
		transition: transform 0.5s ease, visibility 0.5s ease;
		transform: translateX(-110%);
		visibility: hidden;
	}

	li:first-child a {
		border-top-right-radius: 0.25rem;
	}

	svg {
		width: 1rem;
		height: 1rem;
		margin-right: 0.5rem;
		color: variables.$black;
	}

	a {
		display: flex;
		flex-direction: row;
	}

	a,
	.mode {
		flex: 1;
		padding: 0.75rem;
		color: variables.$black;
		padding: 0.75rem;
		background-color: var(--gold);
		border: 2px solid transparent;
		transition: 0.25s ease;
		cursor: pointer;

		&:hover {
			-webkit-filter: brightness(110%);
			filter: brightness(110%);
			border-color: var(--secondary);
		}

		&:active {
			-webkit-filter: brightness(120%);
			filter: brightness(120%);
		}
	}

	.mode {
		display: flex;

		&.dark {
			.moon {
				opacity: 1;
				transform: rotate(0);
			}
		}

		&.light {
			.sun {
				opacity: 1;
				transform: rotate(0);
			}

			.mode_slider_circle {
				transform: rotateY(180deg);
			}
		}
	}

	.mode_icon {
		width: 1rem;
		height: 1rem;
		display: grid;
		margin-right: 0.5rem;

		svg {
			margin: 0;
			grid-column: 1;
			grid-row: 1;
			transition: 0.5s ease;
			opacity: 0;
			transform: rotate(180deg);
		}
	}

	.mode_slider {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin-left: 0.5rem;
		border-radius: 1rem;
		background-color: var(--primary);
		transition: 0.5s ease;
	}

	.mode_slider_circle {
		width: 8px;
		height: 8px;
		margin: 2px;
		border-radius: 50%;
		background-color: var(--secondary);
		transition: 0.5s ease;
	}

	.socials {
		display: flex;
		flex-direction: row;

		a {
			flex: 1;
			padding: 0.25rem;
			transition: 0.25s ease;
		}

		svg {
			width: 1rem;
			height: 1rem;
			margin: auto;
			color: variables.$black;
		}

		path {
			transition: 0.25s ease;
		}

		.email {
			border-bottom-right-radius: 0.25rem;
		}

		.linkedin {
			path:last-child {
				fill: var(--gold);
			}
		}
	}

	.ham_menu {
		position: relative;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		display: block;
		padding: 12px;
		background-color: var(--gold);
		transition: 0.25s ease;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		z-index: 1;

		span {
			display: block;
			width: 27px;
			height: 4px;
			position: relative;
			background: variables.$black;
			border-radius: 200px;
			z-index: 1;
			transition: 0.5s ease;
		}

		span:first-child {
			margin-bottom: 5px;
		}

		span:last-child {
			margin-top: 5px;
		}
	}

	&.open_menu {
		ul {
			transform: translateX(0);
			visibility: visible;
		}

		.ham_menu {
			span:first-child {
				transform: translate(0px, 9px) rotate(45deg);
			}

			span:nth-last-child(2) {
				transform: rotateY(90deg);
				opacity: 0;
			}

			span:last-child {
				transform: translate(0px, -9px) rotate(-45deg);
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	.nav {
		.container {
			width: 120px;
		}

		.mode {
			&.light {
				.mode_slider_circle {
					transform: translateX(1rem);
				}
			}
		}

		.mode_slider {
			width: 2rem;
		}

		.mode_slider_circle {
			width: 10px;
			height: 10px;
			margin: 2px;
		}
	}
}

@media only screen and (min-width: 800px) {
	.nav {
		width: 100%;
		transition: 0.5s ease;

		.container {
			width: 100%;
			display: flex;
			flex-direction: row;
		}

		ul {
			flex: 1;
			position: static;
			width: unset;
			flex-direction: row;
			align-items: center;
			background-color: variables.$blue;
			padding-left: 0.5rem;
			transform: translate(0, -110%);
		}

		a,
		.mode {
			padding: 0.5rem;
			margin: 0.5rem;
			border-radius: 0.25rem;
		}

		li:last-child {
			margin-left: auto;
		}

		.socials {
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		&.open_menu {
			ul {
				transform: translateY(0);
			}
		}
	}
}

@media only screen and (min-width: 1000px) {
	.nav {
		ul {
			transform: translate(0);
			visibility: visible;
			padding: 0;
			flex-direction: row;
		}

		.mode_slider_circle {
			width: 12px;
			height: 12px;
		}

		.ham_menu {
			display: none;
		}
	}
}
