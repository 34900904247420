:root {
	font-family: 'Rubik', sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	scroll-behavior: smooth;
	min-width: 400px;
}

* {
	box-sizing: border-box;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.5rem;
}

h4 {
	font-size: 1.25rem;
}

p {
	font-size: 1.25rem;
	line-height: 1.5rem;
}

a {
	text-decoration: none;
	cursor: pointer;
}

img {
	display: block;
}

@media only screen and (min-width: 600px) {
	:root {
		font-size: 14px;
	}
}

@media only screen and (min-width: 1000px) {
	:root {
		font-size: 16px;
	}
}
