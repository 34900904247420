@use './variables';

.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 50;
}

.background {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: variables.$black;
	opacity: 0.5;
}

.container {
	position: absolute;
	top: 5%;
	bottom: 5%;
	left: 5%;
	right: 5%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}
