$black: rgb(0, 0, 0);
$blue: rgba(0, 125, 200, 0.75);

[data-theme-mode='light'] {
	--primary: rgb(225, 225, 200);
	--secondary: rgb(0, 50, 80);
	--gold: rgb(235, 185, 0);
}

[data-theme-mode='dark'] {
	--primary: rgb(0, 50, 80);
	--secondary: rgb(235, 235, 200);
	--gold: rgb(200, 155, 0);
}
